.jobs {

    .status {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        border-radius: 8px;

        &__1 {
            background-color: #A9DEF9;
        }

        &__2 {
            background-color: #ede7b1;
        }

        &__3 {
            background-color: #bce784;
        }

        &__4 {
            background-color: #f2d492;
        }

        &__5 {
            background-color: #e4c1f9;
        }

        &__6 {
            background-color: #d3f8e2;
        }

        &__7 {
            background-color: #b5b5b5;
        }
    }
}
