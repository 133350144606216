.header {
  height: 64px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  // border-top: 1px solid rgba(204, 204, 204, 0.432);
  padding: 8px 8px;

  background-color: white;

  h1 {
    margin: 0;
  }
}
